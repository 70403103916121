<template>
  <b-modal
    id="CreateModal"
    centered
    ref="CreateModal"
    modal-class="spirecta-modal"
    size="lg"
    @show="onShow"
    @hide="onHide"
  >
    <template slot="modal-title">
      {{ $t(translationPath + 'title_' + accountType) }}
    </template>
    <loader v-if="isLoading" />
    <div v-show="!isLoading">
      <b-form>
        <!-- Account type -->
        <b-form-group
          v-if="isFieldTypeShown"
          label-class="pr-4 text-black"
          label-for="account_type"
          label-cols-lg="2"
          required
          :label="$t(translationPath + 'labels.account_type')"
        >
          <b-form-select
            id="account_type"
            v-model="accountType"
            :options="accountTypeOptions"
            @input="onAccountTypeInput"
          />
        </b-form-group>

        <!-- Account title -->
        <b-form-group
          label-class="pr-4 text-black"
          label-for="account_title"
          label-cols-lg="2"
          required
          :label="$t(translationPath + 'labels.account_title')"
          :invalid-feedback="invalidAccountTitleFeedback"
        >
          <template slot="description">
            <template v-if="accountType === 'income'">
              {{ $t('incomes_expenses.accounts.create_account_wizard.income.step1.title_help_text_income') }}
            </template>
            <template v-else-if="accountType === 'expense'">
              {{ $t('incomes_expenses.accounts.create_account_wizard.expense.step1.title_help_text_expense') }}
            </template>
            <template v-else-if="accountType === 'asset'">
              {{ $t('assets_liabilities.create_account_wizard.assets.step1.title_help_text_asset') }}
            </template>
            <template v-else-if="accountType === 'liability'">
              {{ $t('assets_liabilities.create_account_wizard.liabilities.step1.title_help_text_liability') }}
            </template>
          </template>

          <b-form-input
            ref="InputAccountTitle"
            id="account_title"
            v-model="accountTitle"
            :placeholder="$t(translationPath + 'labels.account_title_placeholder')"
            :state="$v.accountTitle.$dirty ? !$v.accountTitle.$error : null"
          />
        </b-form-group>

        <!-- Account group -->
        <b-form-group
          label-class="pr-4 text-black"
          label-for="account_group"
          label-cols-lg="2"
          required
          :label="$t(translationPath + 'labels.account_group')"
        >
          <b-form-select
            id="account_group_id"
            v-model="accountGroupId"
            :options="accountGroupOptions"
            @input="onAccountGroupInput"
          />
        </b-form-group>

        <!-- Account group title -->
        <b-form-group
          v-if="!accountGroupId"
          label-class="pr-4 text-black"
          label-for="account_group_title"
          label-cols-lg="2"
          required
          :label="$t(translationPath + 'labels.account_group_title')"
          :invalid-feedback="invalidAccountGroupTitleFeedback"
          :description="$t('assets_liabilities.create_account_wizard.common.account_group_description')"
        >
          <b-form-input
            ref="InputAccountGroupTitle"
            id="account_group_title"
            v-model="accountGroupTitle"
            :placeholder="$t(translationPath + 'labels.account_group_title_placeholder')"
            :state="$v.accountGroupTitle.$dirty ? !$v.accountGroupTitle.$error : null"
            @keypress.native="onAccountGroupTitleKeypress"
          />
        </b-form-group>

        <!-- Is importable -->
        <b-form-group
          v-if="['asset', 'liability'].indexOf(accountType) !== -1"
          label-class="pr-4 text-black"
          label-for="is_importable"
          label-cols-lg="2"
          :label="$t('assets_liabilities.create_account_wizard.common.allow_import')"
        >
          <b-form-select
            id="is_importable"
            v-model="isImportable"
            :options="yesNoOptions"
          />
          <template slot="description">
            {{ $t('assets_liabilities.create_account_wizard.common.is_importable_description') }}
          </template>
        </b-form-group>

        <!-- Initial value -->
        <div v-if="['asset', 'liability'].indexOf(accountType) !== -1">
          <h4>{{ $t('assets_liabilities.create_account_wizard.common.initial_value_heading') }}</h4>
        </div>
        <b-form-group
          v-if="['asset', 'liability'].indexOf(accountType) !== -1"
          label-class="pr-4 text-black"
          label-for="initial-value"
          label-cols-lg="2"
          :label="$t('assets_liabilities.create_account_wizard.common.initial_value')"
          :state="$v.initialValue.$dirty ? !$v.initialValue.$error : null"
          :invalid-feedback="invalidInitialValueFeedback"
          :description="$t('assets_liabilities.create_account_wizard.common.initial_value_description')"
        >
          <currency-input
            ref="CurrencyInputInitialValue"
            id="initial-value"
            v-model="initialValue"
            class="mb-2"
            :input-class="initialValueFieldClasses"
            @input="$refs.CurrencyInputInitialValue.$rerender()"
          />
        </b-form-group>

        <!-- Initial value date -->
        <b-form-group
          v-if="['asset', 'liability'].indexOf(accountType) !== -1"
          label-class="pr-4 text-black"
          label-for="initial-value-date"
          label-cols-lg="2"
          required
          :label="$t('assets_liabilities.create_account_wizard.common.initial_value_date')"
          :state="$v.initialValueDate.$dirty ? !$v.initialValueDate.$error : null"
          :invalid-feedback="invalidInitialDateFeedback"
          :description="$t('assets_liabilities.create_account_wizard.common.initial_value_date_description')"
        >
          <datepicker
            id="initial-value-date"
            v-model="initialValueDate"
            :input-class="initialValueDateFieldClasses"
            :typeable="true"
            :required="true"
            :format="'yyyy-MM-dd'"
            :placeholder="'YYYY-MM-DD'"
            :language="selectedLang"
            aria-describedby="input-2-live-feedback"
            :monday-first="startWeekByMonday"
          />
        </b-form-group>
      </b-form>
    </div>
    <template slot="modal-footer" class="text-center">
      <b-button variant="outline" @click="onCancel">{{ $t('common.cancel') }}</b-button>
      <b-button :disabled="isLoading" variant="primary" @click="onOk">{{ $t('common.ok') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import CurrencyInput from '@/components/common/CurrencyInput.vue'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment/moment'
import { da, en, sv } from 'vuejs-datepicker/dist/locale'

const groupTitleRequired = getter => function () {
  if (this.accountGroupId) {
    return true
  }

  return !(!this.accountGroupId && !this.accountGroupTitle)
}

const isGroupTitleUnique = getter => function () {
  const titles = []
  const types = ['income', 'expense', 'asset', 'liability']
  types.map(type => {
    this.accountGroups[type].map(group => {
      titles.push(group.title)
    })
  })

  return titles.indexOf(this.accountGroupTitle) === -1
}

const isTitleUnique = getter => function () {
  return this.usedAccountTitles.indexOf(this.accountTitle) === -1
}

const isDateFormatCorrect = getter => function (val) {
  return moment(val).isValid()
}

const isInitialValueNotNegative = getter => function () {
  return this.initialValue >= 0
}

export default {
  name: 'CreateAccountGroupAccountModal',
  components: { CurrencyInput, Datepicker, Loader },
  props: {
    isFieldTypeShown: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      translationPath: 'common.create_account_group_account_modal.',
      isLoading: true,
      accountType: 'income',
      accountGroupId: -1,
      accountGroups: { income: [], expense: [], asset: [], liability: [] },
      accountGroupTitle: '',
      accountTitle: '',
      isImportable: 0,
      usedAccountTitles: [],
      lastStoredGroup: null,
      lastStoredAccount: null,
      meta: null,
      initialValue: 0,
      initialValueDate: (new Date()).toISOString().substring(0, 10),
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      },
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false
    }
  },
  validations: {
    accountTitle: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(191),
      isTitleUnique: isTitleUnique()
    },
    accountGroupTitle: {
      groupTitleRequired: groupTitleRequired(),
      minLength: minLength(2),
      maxLength: maxLength(191),
      isGroupTitleUnique: isGroupTitleUnique()
    },
    initialValue: { required, isInitialValueNotNegative: isInitialValueNotNegative() },
    initialValueDate: { required, isDateFormatCorrect: isDateFormatCorrect() },
    form: ['accountTitle', 'accountGroupTitle', 'initialValue', 'initialValueDate']
  },
  computed: {
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    accountTypeOptions () {
      return [
        { value: 'income', text: this.$t('common.income') },
        { value: 'expense', text: this.$t('common.expense') },
        { value: 'asset', text: this.$t('common.asset') },
        { value: 'liability', text: this.$t('common.liability') }
      ]
    },
    accountGroupOptions () {
      const options = []
      this.accountGroups[this.accountType].map(group => {
        options.push({ value: group.id, text: group.title })
      })
      options.sort((a, b) => a.text > b.text ? 1 : -1)
      options.push({ value: 0, text: this.$t(this.translationPath + 'labels.new') })
      return options
    },
    yesNoOptions () {
      return [
        { value: 1, text: this.$t('common.yes') },
        { value: 0, text: this.$t('common.no') }
      ]
    },
    invalidAccountTitleFeedback () {
      if (this.$v.accountTitle.required === false) { return this.$t(this.translationPath + 'errors.account_title.required') }
      if (this.$v.accountTitle.minLength === false) { return this.$t(this.translationPath + 'errors.account_title.min_length') }
      if (this.$v.accountTitle.maxLength === false) { return this.$t(this.translationPath + 'errors.account_title.max_length') }
      if (this.$v.accountTitle.isTitleUnique === false) { return this.$t(this.translationPath + 'errors.account_title.not_unique') }
      return ''
    },
    invalidAccountGroupTitleFeedback () {
      if (this.$v.accountGroupTitle.groupTitleRequired === false) { return this.$t(this.translationPath + 'errors.account_group_title.required') }
      if (this.$v.accountGroupTitle.minLength === false) { return this.$t(this.translationPath + 'errors.account_group_title.min_length') }
      if (this.$v.accountGroupTitle.maxLength === false) { return this.$t(this.translationPath + 'errors.account_group_title.max_length') }
      if (this.$v.accountGroupTitle.isGroupTitleUnique === false) { return this.$t(this.translationPath + 'errors.account_group_title.not_unique') }
      return ''
    },
    invalidInitialValueFeedback () {
      if (this.$v.initialValue.required === false) { return this.$t(this.translationPath + 'errors.initial_value.required') }
      if (this.$v.initialValue.isInitialValueNotNegative === false) { return this.$t(this.translationPath + 'errors.initial_value.not_negative') }
      return ''
    },
    invalidInitialDateFeedback () {
      if (this.$v.initialValueDate.required === false) { return this.$t(this.translationPath + 'errors.initial_date.required') }
      if (this.$v.initialValueDate.isDateFormatCorrect === false) { return this.$t(this.translationPath + 'errors.initial_date.invalid_format') }
      return ''
    },
    initialValueFieldClasses () {
      return {
        'is-valid': this.$v.initialValue.$dirty && !this.$v.initialValue.$error,
        'is-invalid': this.$v.initialValue.$dirty && this.$v.initialValue.$error
      }
    },
    initialValueDateFieldClasses () {
      let str = 'form-control input-date mb-2 '
      if (this.$v.initialValueDate.$dirty && !this.$v.initialValueDate.$error) {
        str += ' is-valid'
      } else if (this.$v.initialValueDate.$dirty && this.$v.initialValueDate.$error) {
        str += 'is-invalid'
      }
      return str
    }
  },
  methods: {
    onShow () {
      this.$emit('show')
      process.nextTick(() => {
        this.$refs.InputAccountTitle.focus()
      })
    },
    onHide () {
      this.accountGroupTitle = ''
      this.accountTitle = ''
      this.selectFirstGroup()
      this.lastStoredGroup = null
      this.lastStoredAccount = null
      this.meta = null
      this.$v.form.$reset()
      this.$emit('hide')
    },
    show (params = null, meta = null) {
      this.meta = meta
      if (params !== null) {
        this.applyParams(params)
      }

      this.$refs.CreateModal.show()
      this.$emit('show')
    },
    hide () {
      this.$refs.CreateModal.hide()
      this.$emit('hide')
    },
    onCancel () {
      this.resetToInitialParams()
      this.$refs.CreateModal.hide()
      this.$emit('cancelled')
    },
    async onOk () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.isLoading = true
      const iGroupId = await this.storeAccountGroup()
      if (iGroupId) {
        this.accountGroupId = iGroupId
      }
      await this.storeAccount()
      this.isLoading = false

      this.$emit('performed', {
        storedAccount: this.lastStoredAccount,
        storedAccountGroup: this.lastStoredGroup,
        meta: this.meta
      })

      this.loadAccountsAndGroups()
      this.resetToInitialParams()
      this.hide()
    },
    resetToInitialParams () {
      this.initialValue = 0
      this.initialValueDate = (new Date()).toISOString().substring(0, 10)
      this.isImportable = 0
      this.accountGroupTitle = ''
      this.accountTitle = ''
    },
    selectFirstGroup () {
      process.nextTick(() => {
        if (this.accountGroupOptions.length) {
          this.accountGroupId = this.accountGroupOptions[0].value
        }
      })
    },
    loadAccountsAndGroups () {
      this.isLoading = true
      Promise.all([
        this.loadAccountGroups(),
        this.loadAccounts()
      ])
        .then(() => {
          this.isLoading = false
        })
    },
    async loadAccountGroups () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups-simple`)
          .then(response => {
            this.accountGroups = { income: [], expense: [], asset: [], liability: [] }
            response.data.data.map(group => {
              if (group.code !== 2000) {
                this.accountGroups[group.type].push(group)
              }
            })
            this.selectFirstGroup()
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadAccounts () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts?account_type=income,expense,asset,liability&limit=1000`)
          .then(response => {
            this.usedAccountTitles = []
            response.data.data.map(account => {
              this.usedAccountTitles.push(account.title)
            })
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async storeAccountGroup () {
      return new Promise((resolve, reject) => {
        if (this.accountGroupId) {
          resolve(this.accountGroupId)
        } else {
          const data = {
            type: this.accountType,
            title: this.accountGroupTitle
          }

          axios.post(`${process.env.VUE_APP_ROOT_API}/accounts/groups`, data)
            .then(response => {
              this.lastStoredGroup = response.data.data
              resolve(response.data.data.id)
            })
            .catch(err => {
              console.error(err)
              resolve(err)
            })
        }
      })
    },
    async storeAccount () {
      return new Promise((resolve, reject) => {
        const data = {
          type: this.accountType,
          title: this.accountTitle,
          group_id: this.accountGroupId,
          is_importable: this.isImportable
        }
        if (['income', 'expense'].indexOf(this.accountType) !== -1) {
          data.default_active_percentage = 50
        }
        if (['asset', 'liability'].indexOf(this.accountType) !== -1) {
          data.allow_update = 1
          data.initial_value = this.initialValue
          data.initial_value_date = new Date(this.initialValueDate).toISOString().substring(0, 10)
          data.initial_transaction_title = this.$t('transactions.balance-fix.sidebar.correction.initial_value', {
            account_name: this.accountTitle
          })
        }
        axios.post(`${process.env.VUE_APP_ROOT_API}/accounts`, data)
          .then(response => {
            this.lastStoredAccount = response.data.data
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            resolve(err)
          })
      })
    },
    onAccountTypeInput () {
      if (['income', 'expense'].indexOf(this.accountType) !== -1) {
        this.isImportable = 0
      }
      this.selectFirstGroup()
      this.$refs.InputAccountTitle.focus()
    },
    onAccountGroupInput (groupId) {
      if (!groupId) {
        process.nextTick(() => {
          this.$refs.InputAccountGroupTitle.focus()
        })
      }
    },
    onAccountGroupTitleKeypress (event) {
      if (event.keyCode === 13) {
        this.$refs.InputAccountTitle.focus()
      }
    },
    applyParams (params) {
      if (Object.prototype.hasOwnProperty.call(params, 'type')) {
        this.accountType = params.type
      }
      this.selectFirstGroup()
    }
  },
  created () {
    this.loadAccountsAndGroups()
  }
}
</script>
