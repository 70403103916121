<template>
    <div id="emberSelect" class="ember-select component-custom-select" v-bind:class="dynamicId + (disabled ? ' disabled' : '')">
        <div :key="renderKey" v-bind:id="'emberSelectInput_' + dynamicId" v-click-outside="handleOuterClick" v-resize-window="handleResize" tabindex="0" class="ember-input" :class="classes" @focus="handleShowSuggestion">
            <div class="placeholder" v-if="!inputvalue">{{ computedPlaceholder }}</div>
            <div class="inputText" v-if="inputvalue">{{ inputvalue }}</div>
            <template v-if="!inputClicked && !loadingStatus">
              <b-button variant="primary" class="dropdownBtn" squared @click="handleShowSuggestion"><b-icon-caret-down-fill/></b-button>
            </template>
            <template v-if="inputClicked && !loadingStatus">
              <b-button variant="primary" class="dropdownBtn" squared @click="$root.$emit('custom-select-close')"><b-icon-caret-up-fill/></b-button>
            </template>
            <template v-if="!loadingStatus && enableReloadStatus">
              <b-button variant="primary" class="dropdownBtn" squared @click="reloadContent"><b-icon-arrow-repeat/></b-button>
            </template>
            <span v-if="loadingStatus" class="loadingLoader">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#36b373" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(336.369 50.0001 50)">
                  <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
              </svg>
            </span>
        </div>
        <div class="ember-absolute" v-show="inputClicked && !loadingStatus" v-bind:class="dynamicId" role="listbox">
            <div class="ember-suggestions" v-bind:style="{width: selectWidth + 'px', minWidth: minWidth + 'px'}">
                <div class="suggestion-search" v-if="searchEnabled">
                    <b-form-input v-model.trim="search" :placeholder="computedSearchPlaceholder" v-focus-event="resetIndex"></b-form-input>
                </div>
                <div class="suggestion-list" v-bind:id="'suggestion_list_'+dynamicId">
                    <ul id="suggestLists" class="suggest-list" @keyup.up="handleUpFocus" @keydown.down="handleDownFocus">
                        <template v-if="optionValues && optionValues.length > 0 && !groupingValues && !groupingLabel && !label">
                            <li class="ember-grouping empty"
                              v-for="(items, keys) in optionValues"
                              v-bind:key="keys"
                              @click="selectValue(items)"
                              @keypress.enter="selectValue(items)"
                              v-bind:class="{'active': inputvalue === items, 'enableDiselect': enableDiselect}"
                              tabindex="0"
                              role="option">
                              {{ items }}
                            </li>
                        </template>
                        <template v-else-if="optionValues && optionValues.length > 0 && !groupingValues && !groupingLabel && label">
                            <li class="ember-grouping empty singleLevel"
                              v-for="(items, keys) in optionValues"
                              v-bind:key="keys"
                              @click="selectValue(items)"
                              @keypress.enter="selectValue(items)"
                              v-bind:class="{'active': selectedData && compareObjects(selectedData,items), 'enableDiselect': enableDiselect}"
                              tabindex="0"
                              role="option">
                              {{ items[label] }}
                            </li>
                        </template>
                        <template v-else-if="optionValues && optionValues.length > 0 && groupingValues && groupingLabel && !label">
                            <li class="ember-grouping" v-for="(items, keys) in optionValues" v-bind:key="keys">
                                <span>{{items[groupingLabel] && $te('common.' + items[groupingLabel].toLowerCase()) ? $t('common.' + items[groupingLabel].toLowerCase()) : items[groupingLabel]}}</span>
                                <ul class="ember-grouping-list">
                                    <li v-for="(groupitem, groupkeys) in items[groupingValues]"
                                      v-bind:key="groupkeys"
                                      @click="selectValue(groupitem)"
                                      @keypress.enter="selectValue(groupitem)"
                                      v-bind:class="{'active': selectedData && compareObjects(selectedData,groupitem), 'enableDiselect': enableDiselect}"
                                      tabindex="0"
                                      role="option"
                                      >
                                      {{ groupitem }}
                                    </li>
                                </ul>
                            </li>
                        </template>
                        <template v-else-if="optionValues && optionValues.length > 0 && groupingValues && groupingLabel && label && !groupingLabelSecondary && !groupingValuesSecondary">
                            <li class="ember-grouping" v-for="(items, keys) in optionValues" v-bind:key="keys">
                                <span>{{items[groupingLabel] && $te('common.' + items[groupingLabel].toLowerCase()) ? $t('common.' + items[groupingLabel].toLowerCase()) : items[groupingLabel]}}</span>
                                <ul class="ember-grouping-list">
                                    <li v-for="(groupaccountitem, groupaccountkeys) in items[groupingValues]"
                                      v-bind:key="groupaccountkeys"
                                      @click="selectValue(groupaccountitem)"
                                      @keypress.enter="selectValue(groupaccountitem)"
                                      v-bind:class="{'active':  selectedData && compareObjects(selectedData,groupaccountitem), 'enableDiselect': enableDiselect}"
                                      tabindex="0"
                                      role="option"
                                      >
                                      {{ groupaccountitem[label] }}
                                    </li>
                                </ul>
                            </li>
                        </template>
                        <template v-else-if="optionValues && optionValues.length > 0 && groupingValues && groupingLabel && label && groupingLabelSecondary && groupingValuesSecondary">
                          <template v-for="(items, keys) in optionValues">
                            <li class="ember-grouping" v-bind:key="dynamicId + 'groupingList' + keys.toString()">
                              <template v-if="items && Object.prototype.hasOwnProperty.call(items, groupingLabel) && items[groupingLabel]">
                                <span> {{ items[groupingLabel] }}</span>
                              </template>
                              <ul class="ember-grouping-list-group" v-bind:key="dynamicId + 'groupingUList' + keys.toString()">
                                <template v-for="(groupitem, groupkeys) in items[groupingValuesSecondary]">
                                  <li v-bind:key="dynamicId + keys.toString() + 'mainEle'  + groupkeys.toString()" class="groupName">
                                    <template v-if="displayGroupWithTitle === true">
                                      <span class="groupText">{{ items[groupingLabel] + ' / ' + groupitem[groupingLabelSecondary] }}</span>
                                    </template>
                                    <template v-else>
                                      <span>{{ groupitem[groupingLabelSecondary] }}</span>
                                    </template>
                                    <ul class="ember-grouping-list">
                                      <template v-for="(groupitem, groupkeys) in groupitem[groupingValues]">
                                        <li
                                          v-bind:key="dynamicId + 'options' + groupkeys.toString()"
                                          @click="selectValue(groupitem)"
                                          @keypress.enter="selectValue(groupitem)"
                                          v-bind:class="{'active': selectedData && compareObjects(selectedData,groupitem), 'enableDiselect': enableDiselect}"
                                          tabindex="0"
                                          role="option"
                                        >
                                          {{ groupitem[label] ? groupitem[label] : null  }}
                                        </li>
                                      </template>
                                    </ul>
                                  </li>
                                </template>
                              </ul>
                            </li>
                          </template>
                        </template>
                        <template v-else>
                            <li class="ember-empty-grouping">
                                <span>{{ noResultText }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import { BIconCaretDownFill, BIconCaretUpFill, BIconArrowRepeat } from 'bootstrap-vue'
// import Vue from 'vue'
const currentDate = new Date()
let uid = 0
/**

 const accountOptions = [{ name: 'Inkomster', accounts: [], groupAccounts: [{ text: 'Test', accounts: [{ text: 'Barn - label', value: { text: 'Barn - text', value: 99 } }] }] }]

 <custom-select
 :options="accountOptions"
 :displayGroupWithTitle="true"
 groupingValues="accounts"
 groupingLabel="name"
 groupingValuesSecondary="groupAccounts"
 groupingLabelSecondary="text"
 :placeholder="accountPlaceholder"
 :search-placeholder="$t('common.type_to_filter')"
 :noResultText="$t('common.no_option_found')"
 :loading="loadingAccounts"
 @open="loadAccountOptions"
 :sortGroupLabel="false"
 label="text"
 v-model="iSelectedObject"
 >
 </custom-select>

 */
export default {
  name: 'CustomSelect',
  components: {
    BIconCaretDownFill,
    BIconCaretUpFill,
    BIconArrowRepeat
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    /* First level grouping property of object. E.g. title or title_with_code.
     * Most common the account_group_title in account_group-object
     **/
    groupingLabel: {
      type: String,
      default: null
    },
    /* Property with values to group on in option-variable array. If option-array is
     * {title: xxx, accounts: []} then groupinValues will be "accounts". See e.g. AccountPlanExpensesTink.vue
     * */
    groupingValues: {
      type: String,
      default: null
    },
    displayGroupWithTitle: {
      type: Boolean,
      default: false
    },
    enableDiselect: {
      type: Boolean,
      default: false
    },
    groupingLabelSecondary: {
      type: String,
      default: null
    },
    groupingValuesSecondary: {
      type: String,
      default: null
    },
    /* The selectable object. E.g. title or title_with_code. Most common the account_title in the account-object */
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    searchPlaceholder: {
      type: String,
      default: null
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    searchEnabled: {
      type: Boolean,
      default: true
    },
    noResultText: {
      type: String,
      default: 'No result found'
    },
    /* the main data array to display */
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    sortGroupLabel: {
      type: Boolean,
      default: true
    },
    sortByLocale: {
      type: Boolean,
      default: false
    },
    locale: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default () {
        return null
      }
    },
    trackBy: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    extraWidth: {
      type: Number,
      default: 0
    },
    enableReload: {
      type: Boolean,
      default: false
    },
    extraClasses: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      search: null,
      inputClicked: false,
      selectedData: null,
      inputvalue: null,
      keyindexes: null,
      dynamicId: currentDate.getTime().toString(),
      indexedArray: [],
      loadingStatus: false,
      selectWidth: 0,
      minWidth: 0,
      classes: {},
      renderKey: 0
    }
  },
  created () {
    uid++
    this.dynamicId += uid
    // window.addEventListener('click', this.handleOuterClick)
    if (this.value) {
      this.selectedData = this.value
    }
    if (this.options) {
      this.loadOptions(this.options)
    }
    if (this.loading) {
      this.loadingStatus = this.loading
    }

    this.classes = {
      active: this.inputClicked,
      inactive: !this.inputClicked,
      'rounded-pill': this.rounded
    }
    Object.assign(this.classes, this.extraClasses)
    this.renderKey++
  },
  mounted () {
    this.$on('open', () => {
      this.$root.$emit('custom-select-close')
      this.inputClicked = true
      setTimeout(function () {
        this.$el.children[1].children[0].children[0].children[0].focus()
      }.bind(this), 100)
    })
    // Listen to root event
    this.$root.$on('custom-select-close', () => {
      if (this.$el.children[0].className === 'ember-input') {
        this.$el.children[0].blur()
      }
      const dynamicDiv = document.getElementById('suggestion_list_' + this.dynamicId)
      if (dynamicDiv) {
        dynamicDiv.scrollTop = 0
      }
      this.keyindexes = null
      this.search = null
      this.inputClicked = false
    })
    this.setDropDownWidth()
  },
  updated () {
    this.setDropDownWidth()
  },
  destroyed () {
    // window.removeEventListener('click', this.handleOuterClick)
    this.$off('open')
    this.$root.$off('custom-select-close')
  },
  computed: {
    computedPlaceholder () {
      return this.placeholder ? this.placeholder : this.$t('common.custom_select.please_select_an_option')
    },
    computedSearchPlaceholder () {
      return this.searchPlaceholder ? this.searchPlaceholder : this.$t('common.custom_select.type_to_search')
    },
    enableReloadStatus: {
      get: function () {
        return this.enableReload
      },
      set: function (value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.enableReload = value // TODO::Varun fix Unexpected mutation of "enableReload" prop
      }
    },
    optionValues () {
      if (this.search) {
        const optArray = []
        const cloneIndexArray = _.cloneDeep(this.indexedArray)
        cloneIndexArray.filter(item => {
          if (this.groupingValues && !this.groupingValuesSecondary) {
            const groupValues = item[this.groupingValues] || []
            const groupValuesFilter = groupValues.filter(grpItems => {
              const search = this.search || ''
              const normalizedSearch = search.toLowerCase().trim()
              const target = this.label ? this.normalizeElement(grpItems[this.label]) : this.normalizeElement(grpItems)
              return target.indexOf(normalizedSearch) > -1
            })
            return groupValuesFilter.length > 0
          } else if (this.groupingValues && this.groupingValuesSecondary) {
            const groupValues = item[this.groupingValuesSecondary] || []
            const groupValuesFilter = groupValues.filter(grpItems => {
              const accountsFilter = grpItems[this.groupingValues].filter(accountGrp => {
                const search = this.search || ''
                const normalizedSearch = search.toLowerCase().trim()
                const target = this.label ? this.normalizeElement(accountGrp[this.label]) : this.normalizeElement(accountGrp)
                return target.indexOf(normalizedSearch) > -1
              })
              return accountsFilter.length > 0
            })
            return groupValuesFilter.length > 0
          } else {
            const search = this.search || ''
            const normalizedSearch = search.toLowerCase().trim()
            const target = this.label ? this.normalizeElement(item[this.label]) : this.normalizeElement(item)
            return target.indexOf(normalizedSearch) > -1
          }
        }).map(items => {
          if (this.groupingValues && !this.groupingValuesSecondary) {
            const accounts = items[this.groupingValues].filter(grpItems => {
              const search = this.search || ''
              const normalizedSearch = search.toLowerCase().trim()
              const target = this.label ? this.normalizeElement(grpItems[this.label]) : this.normalizeElement(grpItems)
              return target.indexOf(normalizedSearch) > -1
            })
            const newObject = {}
            newObject[this.groupingValues] = accounts
            optArray.push(Object.assign({}, items, newObject))
          } else if (this.groupingValues && this.groupingValuesSecondary) {
            const group = items[this.groupingValuesSecondary].filter(grpItems => {
              const accountsFilter = grpItems[this.groupingValues].filter(accountGrp => {
                const search = this.search || ''
                const normalizedSearch = search.toLowerCase().trim()
                const target = this.label ? this.normalizeElement(accountGrp[this.label]) : this.normalizeElement(accountGrp)
                return target.indexOf(normalizedSearch) > -1
              })
              return accountsFilter.length > 0
            })
            const newObject = {}
            newObject[this.groupingValuesSecondary] = group
            newObject[this.groupingValuesSecondary] = newObject[this.groupingValuesSecondary].filter(grpItems => {
              const accountsFilter = grpItems[this.groupingValues].filter(accountGrp => {
                const search = this.search || ''
                const normalizedSearch = search.toLowerCase().trim()
                const target = this.label ? this.normalizeElement(accountGrp[this.label]) : this.normalizeElement(accountGrp)
                return target.indexOf(normalizedSearch) > -1
              })
              grpItems[this.groupingValues] = accountsFilter
              return accountsFilter.length > 0
            })
            optArray.push(Object.assign({}, items, newObject))
          } else {
            const search = this.search || ''
            const normalizedSearch = search.toLowerCase().trim()
            const target = this.label ? this.normalizeElement(items[this.label]) : this.normalizeElement(items)
            if (target.indexOf(normalizedSearch) > -1) {
              optArray.push(items)
            }
          }
          return items
        })
        return optArray
      }
      return this.indexedArray
    }
  },
  watch: {
    extraClasses: {
      deep: true,
      handler () {
        Object.assign(this.classes, this.extraClasses)
        this.renderKey++
      }
    },
    selectedData: function () {
      if (this.selectedData) {
        if (this.label) {
          this.inputvalue = this.selectedData[this.label] || null
        } else {
          this.inputvalue = this.selectedData
        }
      } else {
        this.inputvalue = null
      }
    },
    value: function (newVal) {
      if (newVal) {
        this.selectedData = newVal
      } else {
        this.selectedData = null
      }
    },
    loading: function (newVal) {
      if (newVal) {
        this.loadingStatus = newVal
      }
    },
    options: function () {
      if (this.options) {
        this.loadOptions(this.options)
      }
    }
  },
  methods: {
    reloadContent () {
      this.$emit('reload-content')
    },
    setDropDownWidth () {
      const dynWd = document.getElementById('emberSelectInput_' + this.dynamicId)
      const latestWidth = this.extraWidth > 45 ? 45 : (this.extraWidth > 30 ? this.extraWidth : 0)
      this.selectWidth = screen.width > 991 ? (dynWd.offsetWidth + latestWidth) : dynWd.offsetWidth
    },
    resetIndex () {
      this.keyindexes = null
    },
    compareObjects (a, b) {
      return _.isEqual(a, b)
    },
    handleResize (e) {
      this.setDropDownWidth()
    },
    handleOuterClick (e) {
      const path = e.path || (e.composedPath && e.composedPath())
      const pathClasses = path.filter(item => {
        const RegExpR = new RegExp('^ember', 'g')
        return item.className && RegExpR.test(item.className)
      })
      if (pathClasses.length === 0) {
        if (this.$el.children[0].className === 'ember-input') {
          this.$el.children[0].blur()
        }
        const dynamicDiv = document.getElementById('suggestion_list_' + this.dynamicId)
        if (dynamicDiv) {
          dynamicDiv.scrollTop = 0
        }
        this.keyindexes = null
        this.search = null
        this.inputClicked = false
        this.$root.$emit('custom-select-close')
      }
    },
    loadOptions (options) {
      let opt = []
      if (options) {
        // this.loadingStatus = true
        for (const items in options) {
          opt.push(options[items])
        }
        if (this.sortGroupLabel) {
          const sortOrder = { income: '1', expense: '2', asset: '3', liability: '4' }
          opt = opt.sort(function (a, b) {
            const nameA = this.groupingLabel ? (sortOrder[a.type] + a[this.groupingLabel] || null) : (a || null)
            const nameB = this.groupingLabel ? (sortOrder[b.type] + b[this.groupingLabel] || null) : (b || null)
            // sort by locale
            if (this.sortByLocale && this.locale) {
              return nameA.localeCompare(nameB, this.locale)
            }
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }.bind(this))
        }
      }
      if (this.groupingLabel && this.groupingValues && !this.groupingValuesSecondary && !this.groupingLabelSecondary) {
        const manipulateAccounts = opt.map(item => {
          const accounts = item[this.groupingValues].sort(function (a, b) {
            const nameA = this.label ? (a[this.label] || null) : (a || null)
            const nameB = this.label ? (b[this.label] || null) : (b || null)
            // sort by loale
            if (this.sortByLocale && this.locale) {
              return nameA.localeCompare(nameB, this.locale)
            }
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }.bind(this))
          const newObject = {}
          newObject[this.groupingValues] = accounts
          return Object.assign(item, newObject)
        })
        /*
        */
        const accountsFilter = opt.map(item => item[this.groupingValues])
        const flatenAcounts = accountsFilter.length > 0 ? _.flatten(accountsFilter) : []
        const maxInputLength = [...flatenAcounts].map((el) => el[this.label].length).sort(function (a, b) { return a - b })
        if (maxInputLength.length > 0) {
          const minWidth = parseFloat(maxInputLength[maxInputLength.length - 1] * 7.25)
          this.minWidth = minWidth <= 300 ? minWidth : 238
        }
        if (this.selectedData) {
          const filterAccounts = flatenAcounts.filter(item => {
            if (this.label) {
              return item[this.label] === this.selectedData[this.label]
            } else {
              return item === this.selectedData
            }
          })
          if (flatenAcounts.length > 0) {
            this.selectedData = filterAccounts.length > 0 ? filterAccounts[0] : null
            this.$emit('input', this.selectedData)
          }
          // this.$emit('select', this.selectedData)
        }
        this.indexedArray = manipulateAccounts
        this.loadingStatus = false
      } else if (this.groupingLabel && this.groupingValues && this.groupingValuesSecondary && this.groupingLabelSecondary) {
        const manipulateAccounts = opt.map(item => {
          const groups = item[this.groupingValuesSecondary].sort(function (a, b) {
            const nameA = a[this.groupingLabelSecondary] || null
            const nameB = b[this.groupingLabelSecondary] || null
            // sort by loale
            if (this.sortByLocale && this.locale) {
              return nameA.localeCompare(nameB, this.locale)
            }
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          }.bind(this))
          const mapGroupAccounts = groups.map(accountsItem => {
            const accounts = accountsItem[this.groupingValues].sort(function (a, b) {
              const nameA = (this.label) ? (a[this.label] || null) : a
              const nameB = (this.label) ? (b[this.label] || null) : b
              // sort by loale
              if (this.sortByLocale && this.locale) {
                return nameA.localeCompare(nameB, this.locale)
              }
              if (nameA < nameB) {
                return -1
              }
              if (nameA > nameB) {
                return 1
              }
              return 0
            }.bind(this))
            const newObj = {}
            newObj[this.groupingValues] = accounts
            return Object.assign(accountsItem, newObj)
          })
          const newObject = {}
          newObject[this.groupingValuesSecondary] = mapGroupAccounts
          return Object.assign(item, newObject)
        })
        /*
        */
        const groupFilter = opt.map(item => item[this.groupingValuesSecondary])
        const accountsFilter = []
        groupFilter.map(item => {
          item.map(itemsAcc => {
            accountsFilter.push(itemsAcc[this.groupingValues])
          })
        })
        const flatenAcounts = accountsFilter.length > 0 ? _.flatten(accountsFilter) : []
        const maxInputLength = [...flatenAcounts].map((el) => el[this.label].length).sort(function (a, b) { return a - b })
        if (maxInputLength.length > 0) {
          const minWidth = parseFloat(maxInputLength[maxInputLength.length - 1] * 7.25)
          this.minWidth = minWidth <= 300 ? minWidth : 238
        }
        if (this.selectedData) {
          const filterAccounts = flatenAcounts.filter(item => {
            if (this.label) {
              return item[this.label] === this.selectedData[this.label]
            } else {
              return item === this.selectedData
            }
          })
          if (flatenAcounts.length > 0) {
            this.selectedData = filterAccounts.length > 0 ? filterAccounts[0] : null
            this.$emit('input', this.selectedData)
          }
          // this.$emit('select', this.selectedData)
        }
        this.indexedArray = manipulateAccounts
        this.loadingStatus = false
      } else {
        this.indexedArray = opt
        this.loadingStatus = false
      }
    },
    handleShowSuggestion (e) {
      this.$root.$emit('custom-select-close')
      if (!this.loadingStatus) {
        this.$emit('open')
      } else {
        const selectInput = document.getElementById('emberSelectInput_' + this.dynamicId)
        selectInput.blur()
      }
    },
    handleHideSuggestion () {
      this.$emit('close')
      this.keyindexes = null
      this.search = null
      this.inputClicked = false
    },
    selectValue (value) {
      const valueEqual = this.selectedData ? _.isEqual(this.selectedData, value) : false
      if (this.selectedData && this.enableDiselect && valueEqual) {
        this.selectedData = null
        this.search = null
        this.$emit('select', null)
        this.$emit('input', null)
        if (this.closeOnSelect) {
          this.handleHideSuggestion()
        }
      } else {
        this.selectedData = value
        this.search = null
        this.$emit('select', value)
        this.$emit('input', value)
        if (this.closeOnSelect) {
          this.handleHideSuggestion()
        }
      }
    },
    normalizeElement (str) {
      if (str === null) str = 'null'
      if (str === undefined) str = 'undefined'
      if (str === false) str = 'false'
      return str.toString().toLowerCase()
    },
    handleUpFocus (e) {
      if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault()
      }
      let options = []
      if ((this.groupingValues && this.groupingLabel) || (this.groupingValuesSecondary && this.groupingLabelSecondary)) {
        if (this.groupingValuesSecondary && this.groupingLabelSecondary) {
          const mainDiv = this.$el.children[1].children[0].children[1].children[0].children
          for (let i = 0; i <= mainDiv.length - 1; i++) {
            for (let j = 0; j <= mainDiv[i].children[1].children.length - 1; j++) {
              for (let k = 1; k <= mainDiv[i].children[1].children[j].children.length - 1; k++) {
                for (let l = 0; l <= mainDiv[i].children[1].children[j].children[1].children.length - 1; l++) {
                  options.push(mainDiv[i].children[1].children[j].children[1].children[l])
                }
              }
            }
          }
        } else {
          const mainDiv = this.$el.children[1].children[0].children[1].children[0].children
          for (let i = 0; i <= mainDiv.length - 1; i++) {
            for (let j = 0; j <= mainDiv[i].children[1].children.length - 1; j++) {
              options.push(mainDiv[i].children[1].children[j])
            }
          }
        }
      } else {
        options = this.$el.children[1].children[0].children[1].children[0].children
      }
      if (options.length > 0) {
        if (this.keyindexes !== null && this.keyindexes !== 0) {
          options[this.keyindexes].blur()
          this.keyindexes = this.keyindexes - 1
          options[this.keyindexes].focus()
        }
      }
    },
    handleDownFocus (e) {
      if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
        e.preventDefault()
      }
      let options = []
      if ((this.groupingValues && this.groupingLabel) || (this.groupingValuesSecondary && this.groupingLabelSecondary)) {
        if (this.groupingValuesSecondary && this.groupingLabelSecondary) {
          const mainDiv = this.$el.children[1].children[0].children[1].children[0].children
          for (let i = 0; i <= mainDiv.length - 1; i++) {
            for (let j = 0; j <= mainDiv[i].children[1].children.length - 1; j++) {
              for (let k = 1; k <= mainDiv[i].children[1].children[j].children.length - 1; k++) {
                for (let l = 0; l <= mainDiv[i].children[1].children[j].children[1].children.length - 1; l++) {
                  options.push(mainDiv[i].children[1].children[j].children[1].children[l])
                }
              }
            }
          }
        } else {
          const mainDiv = this.$el.children[1].children[0].children[1].children[0].children
          for (let i = 0; i <= mainDiv.length - 1; i++) {
            for (let j = 0; j <= mainDiv[i].children[1].children.length - 1; j++) {
              options.push(mainDiv[i].children[1].children[j])
            }
          }
        }
      } else {
        options = this.$el.children[1].children[0].children[1].children[0].children
      }
      if (options.length > 0) {
        if (this.keyindexes === null) {
          this.keyindexes = 0
          options[this.keyindexes].focus()
        } else if (this.keyindexes !== null && this.keyindexes !== options.length - 1) {
          options[this.keyindexes].blur()
          this.keyindexes = this.keyindexes + 1
          options[this.keyindexes].focus()
        }
      }
    }
  },
  directives: {
    'focus-event': {
      bind: function (el, binding, vnode) {
        el.focusInputEvent = function (event) {
          vnode.context[binding.expression](event)
        }
        el.addEventListener('focus', el.focusInputEvent)
      },
      unbind: function (el) {
        el.removeEventListener('focus', el.focusInputEvent)
      }
    },
    'click-outside': {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    },
    'resize-window': {
      bind: function (el, binding, vnode) {
        el.windowResizeEvent = function (event) {
          vnode.context[binding.expression](event)
        }
        window.addEventListener('resize', el.windowResizeEvent)
      },
      unbind: function (el) {
        window.removeEventListener('resize', el.windowResizeEvent)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/custom-select.scss'
</style>
