<template>
    <div>
        <b-tab
            :title="$t(translationPath + 'tab_expense_overview')"
            :active="$route.name === 'account-plan-expenses-overview'"
            @click="clickHandler({ name: 'account-plan-expenses-overview' })"
        >
            <slot name="account-plan-expenses-overview"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_misc')"
          :active="$route.name === 'account-plan-expenses-misc'"
          @click="clickHandler({ name: 'account-plan-expenses-misc' })"
        >
          <slot name="account-plan-expenses-misc"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_match_to_globals')"
            :active="$route.name === 'account-plan-expenses-match-globals'"
            @click="clickHandler({ name: 'account-plan-expenses-match-globals' })"
        >
            <slot name="account-plan-expenses-match-globals"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_expense_tink_match_expense')"
            :active="$route.name === 'account-plan-expenses-tink'"
            @click="clickHandler({ name: 'account-plan-expenses-tink' })"
        >
            <slot name="account-plan-expenses-tink"></slot>
        </b-tab>
        <b-tab
            :title="$t(translationPath + 'tab_title_account_split')"
            :active="$route.name === 'account-plan-expense-ownership'"
            @click="clickHandler({ name: 'account-plan-expense-ownership' })"
        >
            <slot name="account-plan-ownership-global"></slot>
        </b-tab>
        <b-tab
          :title="$t(translationPath + 'tab_title_stats')"
          :active="$route.name === 'account-plan-expenses-stats'"
          @click="clickHandler({ name: 'account-plan-expenses-stats' })"
        >
          <slot name="account-plan-stats-expenses"></slot>
        </b-tab>
    </div>
</template>

<script>
export default {
  name: 'ExpenseMenu',
  data () {
    return {
      translationPath: 'reports.other.account_plan.'
    }
  },
  computed: {
    checkDev () {
      return process.env.VUE_APP_NODE_ENV === 'development'
    }
  },
  methods: {
    clickHandler ({ name }) {
      if (this.$route.name === name) return true
      this.$router.push({ name })
    }
  }
}
</script>
